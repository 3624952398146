import Dialog from "components/dist/atoms/Dialog";
import Text from "components/dist/atoms/Text";
import ActionAlertDialog from "components/dist/molecules/ActionAlertDialog";
import RenameAlertDialog from "components/dist/molecules/RenameAlertDialog";
import MinimizeWindow from "components/dist/organisms/MinimizeWindow";
import NoAccessToFileAlertDialog from "components/dist/organisms/NoAccessToFileAlertDialog";
import { useRouter } from "next/router";
import { after, concat, listen, trigger, useEffectAtMount } from 'polyrhythm-react'
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState, useTransition } from "react";
import { useSubscription } from "react-stomp-hooks";
import { toast } from "react-toastify";
import { AppUserDTO2, BasicLoanDto, DateAsString, LoanDto, LoanPhaseCategoryType, MessageDto, MessageLabel, MessageSendDto, MessageThreadDto, Role, RoleLenderGroupLevel } from "src/backend";
import { UndoToast } from "src/components/dashboard/dashboard-sidebar-shoebox/UndoToast";
import { ElementsSharingDialog } from "src/components/form-elements/elements-sharing-dialog";
import { ElementsSharingDialogProps } from "src/components/form-elements/elements-sharing-dialog/elements-sharing-dialog.types";
import { InlineComposeMessageForm } from "src/components/messages/inline-compose-message-form";
import { InlineComposeMessageFormRefProps } from "src/components/messages/inline-compose-message-form/inline-compose-message-form.types";
import { ListFile } from "src/components/secure-upload-form/secure-upload-form.state";
import { QUERY_MESSAGE_COMPOSE_OPEN, QUERY_MESSAGE_ID, QUERY_MESSAGE_SEARCH_LABEL, QUERY_MESSAGE_SEARCH_TEXT, QUERY_MESSAGE_THREAD_ID, QUERY_PARAM_MESSAGE_LENDER_ID } from "src/constants/chat";
import { QUERY_PARAM_FORM_ELEMENT_IDS, QUERY_PARAM_FORM_ELEMENT_TITLE, QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID, QUERY_PARAM_NEEDS_LIST_DIALOG } from "src/constants/form-element";
import { QUERY_PARAM_SELECT_BORROWER_PORTAL_MESSAGES_NEW_LOANS_DIALOG } from "src/constants/loan";
import { QUERY_PARAM_FORM_ELEMENT_ID, QUERY_PARAM_LOAN_ID } from "src/constants/query-params";
import { QUERY_DOCUMENT_PREVIEW_ID, QUERY_ELEMENT_DOCUMENT_PREVIEW_ID, QUERY_PARAM_VIEWER_ACTIONS_DISABLED } from "src/constants/shoebox";
import { NO_ACCESS_MODAL_ID, Route } from "src/constants/ui";
import { QUERY_PARAM_LENDER_ID, QUERY_PARAM_TASK_VIEW } from "src/constants/url";
import { useGetRecipientsUsers } from "src/hooks/messages/use-get-recipients-list";
import { useUser } from "src/hooks/use-user";
import { useUploadMutation } from "src/services/baseApi";
import { useGetLendersForBorrowerQuery } from "src/services/companyApi";
import { documentApi, useEditDocumentMutation, useLazyGetUploadUrlQuery, usePostUploadUrlMutation } from "src/services/documentApi";
import { loanApi, useGetLoanByIdQuery, useGetLoansForCompanyAndUserQuery } from "src/services/loanApi";
import { messageApi, MessageDtoExtended, MessageThreadDtoExtended, useDeleteDraftMessageMutation, useDeleteMessageMutation, useForwardMessageMutation, useGetLoanThreadQuery, useGetLoanThreadsQuery, useGetOriginalMessageAsHtmlQuery, useGetUnreadCountByCompanyByCompaniesQuery, useGetUnreadCountByCompanyQuery, useLazyGetOriginalMessageAsHtmlQuery, useMuteThreadMutation, useSendDraftMessageMutation, useSendMessageMutation, useUnmuteThreadMutation, useUpdateDraftMessageMutation, useUserTypingInThreadMutation } from "src/services/messageApi";
import { packageApi, useGetLoanElementsQuery, useLazyGenerateDynamicNeedsListBodyQuery } from "src/services/packageApi";
import { taskApi } from "src/services/taskApi";
import { AppDispatch, useDispatch } from "src/store";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import { getFileNameWithoutExtension } from "src/utils";
import { doesTextHaveActionLinks } from "src/utils/does-text-have-action-links";
import { isZipFile } from "src/utils/file/is-zip-file";
import { getExtensionFromFilename } from "src/utils/get-extension-from-filename";
import { cleanMessageBody } from "src/utils/messages/clean-message-body";
import { getActionLinkRedirectUrl } from "src/utils/url/get-action-link-redirect-url";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";
import { getFoldersAndFiles } from "src/utils/zip";
import { useMediaQuery } from "usehooks-ts";

import { MessagesContextReducer, MessageWindowDialogState } from "./message-context.reducer";


type threadId = string;

type fileId = string;

type loanId = string;

// context to hold the state of LoanDto
interface MessagesContextValue {
    threads: MessageThreadDtoExtended[];
    replyToMessage: MessageDtoExtended | null;
    draftMessage: MessageDto | null;
    isLoadingThreads: boolean;
    filterLabel: MessageLabel;
    activeMessageId: string;
    filterQuery: string;
    activeThread?: MessageThreadDtoExtended;
    isLoadingLoans: boolean;
    filterUnread: boolean;
    filterDraft: boolean;
    onCancelSendMessage: () => void;
    onComposeNewMessageClick: () => void;
    onBodyClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    filterOrder: 'ASC' | 'DESC';
    onFilterOrderChange: (order: 'ASC' | 'DESC') => void;
    onForwardMessage: (message: MessageDtoExtended) => void;
    onDeleteMessage: (message: MessageDtoExtended) => void;
    onOpenInWindow: (message: MessageDtoExtended) => void;
    onSetActiveLoan: (loan: BasicLoanDto) => void;
    onFilterLabelChange: (label: MessageLabel) => void;
    onClearThreadFiles: (threadId?: string) => void;
    onCancelSingleFile: (listFile: ListFile) => void;
    onNavigateToThread: (thread: MessageThreadDto) => void;
    onEditorReady: () => void;
    onFilesDrop: (files: File[], threadId: string) => Promise<string[]>;
    onNavigateToMessage: (message: MessageDto) => void;
    onNavigateToDraftMessage: (message: MessageDto) => void;
    onViewOriginalHtml: (message: MessageDto) => void;
    onDigestClick: (userIds: string[]) => void;
    uploadedFiles: Record<threadId, Record<fileId, ListFile>>;
    loan: Pick<BasicLoanDto, 'id' | 'loanRoles' | "projectName" | "shortCode">
    unreadMessagesCount: number;
    loansUnreadMap: Record<loanId, number>;
    unreadLastMassageMap: Record<loanId, DateAsString>;
    messageComposerOpen: boolean;
    loans: BasicLoanDto[];
    messageWindowStateDialog: MessageWindowDialogState;
    loggedInUserId: string;
    loggedInUserRole: Role;
    loggedRoleGroup: RoleLenderGroupLevel;
    isSubmitting: boolean;
    messagesScrollAreaRef: React.RefObject<HTMLDivElement>;
    editorRef: React.RefObject<HTMLInputElement & { setContent?: (text: string) => {}, replaceNeedsList?: () => void }>;
    formRef: React.RefObject<InlineComposeMessageFormRefProps>;
    onFilterUnreadChange: (unread: boolean) => void;
    onFilterDraftChange: () => void;
    onMessageComposerOpenChange: (open: boolean, draftId?: string) => void;
    onSendMessageClick: (elements: FormElementV2ResponseDtoExtended[], args: { recipients: string[], loanId: string }) => void;
    onReplyToMessage: (message: MessageDtoExtended) => void;
    onFilterQueryChange: (query: string) => void;
    onSendMessage: (message: MessageSendDto) => Promise<MessageDtoExtended>;
    onSendDraftMessage: (message: MessageSendDto) => Promise<MessageDtoExtended>;
    onSetDraftMessage: (message: MessageDtoExtended) => void;
    onMuteThreadChange: (threadId: string, isMuted: boolean) => Promise<void>;
    onDeleteDraftMessage: (id: String) => Promise<void>;
    onUpdateDraftMessage: (draftedMessageId: string, message: MessageSendDto) => Promise<MessageDtoExtended>;
    onFileClick: (listFile: ListFile) => void;
    onStageSingleFileForRename: (listFile: ListFile) => void;
    onStageSingleFileForDelete: (listFile: ListFile) => void;
}

// initial values for MessagesContextValue
const initialValues: MessagesContextValue = {
    unreadLastMassageMap: {},
    isLoadingThreads: false,
    isLoadingLoans: false,
    messagesScrollAreaRef: null,
    draftMessage: null,
    filterQuery: '',
    filterUnread: false,
    loggedRoleGroup: null,
    onCancelSendMessage: () => { },
    onComposeNewMessageClick: () => { },
    filterDraft: false,
    filterOrder: 'DESC',
    isSubmitting: false,
    messageWindowStateDialog: "CLOSED",
    onSetDraftMessage: () => { },
    onSetActiveLoan: () => { },
    onFilterOrderChange: () => { },
    onViewOriginalHtml: () => { },
    onEditorReady: () => { },
    loggedInUserId: '',
    loggedInUserRole: null,
    activeThread: null,
    onBodyClick: () => { },
    editorRef: null,
    formRef: null,
    onClearThreadFiles: () => { },
    onFilterUnreadChange: () => { },
    onFilterDraftChange: () => { },
    onNavigateToThread: () => { },
    onFilterLabelChange: () => { },
    onNavigateToMessage: () => { },
    onNavigateToDraftMessage: () => { },
    onForwardMessage: () => { },
    onOpenInWindow: () => { },
    onDeleteMessage: () => void 0,
    onReplyToMessage: () => { },
    loansUnreadMap: {},
    threads: [],
    filterLabel: null,
    onCancelSingleFile: () => { },
    onSendMessageClick: () => { },
    uploadedFiles: {},
    loans: [],
    activeMessageId: '',
    replyToMessage: null,
    onFilesDrop: () => Promise.resolve([]),
    loan: null,
    messageComposerOpen: false,
    unreadMessagesCount: 0,
    onDigestClick: () => { },
    onMessageComposerOpenChange: () => { },
    onSendMessage: () => Promise.resolve(null),
    onSendDraftMessage: () => Promise.resolve(null),
    onMuteThreadChange: () => Promise.resolve(null),
    onDeleteDraftMessage: () => Promise.resolve(null),
    onUpdateDraftMessage: () => Promise.resolve(null),
    onFilterQueryChange: () => { },
    onFileClick: () => { },
    onStageSingleFileForRename: () => { },
    onStageSingleFileForDelete: () => { },
};

// create context

const MessagesContext = createContext<MessagesContextValue>(initialValues);

const stagedFileInitialState = {
    operation: null,
    data: null,
    message: null
}

// context provider
interface StagedFileState {
    operation: 'DELETE' | 'RENAME' | null;
    data: ListFile | null;
    message: MessageDtoExtended | null;
}
interface MessagesContextProviderProps {
    children?: React.ReactNode;
}


export const MessagesContextProvider = (props: MessagesContextProviderProps) => {
    const [state, reducerDispatch] = useReducer(MessagesContextReducer.reducer, MessagesContextReducer.initialState);
    const [transitionPending, setTransition] = useTransition();
    const messagesScrollAreaRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const userState = useUser();
    const editorRef = useRef<HTMLInputElement & { setContent?: (text: string) => {}, getContent?: () => string }>(null);
    const formRef = useRef<InlineComposeMessageFormRefProps>(null);
    const { data: lendersData = [], isLoading: isLoadingLenders } = useGetLendersForBorrowerQuery(null, {
        skip: !userState.isBorrower
    });
    const [sendMessage] = useSendMessageMutation();
    const [sendDraftMessage] = useSendDraftMessageMutation();
    const [muteThread] = useMuteThreadMutation();
    const [unmuteThread] = useUnmuteThreadMutation();
    const [deleteDraftMessage] = useDeleteDraftMessageMutation();
    const [updateDraftMessage] = useUpdateDraftMessageMutation();
    const [updateDocument] = useEditDocumentMutation();
    const [generateDynamicNeedsListBody] = useLazyGenerateDynamicNeedsListBodyQuery();
    const [deleteMessage] = useDeleteMessageMutation();
    const [forwardMessage] = useForwardMessageMutation();
    const { currentData: originalMessageAsHtml } = useGetOriginalMessageAsHtmlQuery({
        messageId: state.originalMessageToViewAsHtml?.id
    }, {
        skip: !state.originalMessageToViewAsHtml

    })

    const [uploadedFiles, setUploadedFiles] = useState<Record<threadId, Record<fileId, ListFile>>>({});
    const [stagedListFile, setStagedListFile] = useState<StagedFileState>(stagedFileInitialState);
    const [getAnonymousUploadUrl] = useLazyGetUploadUrlQuery();
    const [uploadFile] = useUploadMutation();
    const [postUploadUrl] = usePostUploadUrlMutation();
    const [userTypingInThread] = useUserTypingInThreadMutation();

    const router = useRouter();
    let lenderId = router.query[QUERY_PARAM_LENDER_ID] as string;
    // if user is lender is company id
    if (userState.isLender) {
        lenderId = userState.company.id;
    }
    const loanId = router.query[QUERY_PARAM_LOAN_ID] as string;
    const threadId = router.query[QUERY_MESSAGE_THREAD_ID] as string;
    const composeMessageOpenQueryParam = router.query[QUERY_MESSAGE_COMPOSE_OPEN] as string;
    const { currentData: activeThreadData } = useGetLoanThreadQuery({
        threadId,
        loanId
    }, {
        skip: !threadId || !loanId,
        refetchOnMountOrArgChange: true
    })
    const activeThread = !!threadId ? activeThreadData : null
    const { data: loanElements = { list: [] } } = useGetLoanElementsQuery({
        id: loanId,
        view: 'CONVENTIONAL'
    }, {
        skip: !loanId
    })

    const { users: recipientsUsers } = useGetRecipientsUsers({
        loanId,
        type: "ALL"
    });

    const categories: LoanPhaseCategoryType[] = [
        'LEAD',
        'ORIGINATION',
        'PORTFOLIO',
    ]
    if (userState.isLender) {
        categories.push('ARCHIVE')
    }
    const { data: unreadMessagesByLoan } = useGetUnreadCountByCompanyQuery({
        companyId: lenderId,
        categories
    }, {
        skip: !lenderId
    });
    const { data: unreadMessagesCount } = useGetUnreadCountByCompanyByCompaniesQuery({
        companyIds: lendersData.map(lender => lender.id) || [],
        categories
    }, {
        skip: !lendersData || isLoadingLenders
    });

    const { data: loansData = [], isLoading: isLoadingLoans } = useGetLoansForCompanyAndUserQuery({
        companyId: lenderId,
        userId: userState.user.id
    }, {
        skip: !lenderId || !userState.user.id
    });

    const { data: loanThreadsData, isLoading: isLoadingThreads } = useGetLoanThreadsQuery({
        loanId: loanId,
    }, {
        skip: !loanId
    })

    // listen for new messages
    // and refetch loan threads
    const refetchLoanThreads = () => {
        dispatch(messageApi.util.invalidateTags([{
            type: 'MessageThreadDto',
            id: loanId
        }]))
    }

    useSubscription(`/topic/chats/${loanId}`, refetchLoanThreads);

    useSubscription(`/topic/chats/${loanId}`, () => {
        dispatch(messageApi.util.invalidateTags(['MessageUnreadDto']))
    });

    useEffectAtMount(() => {
        listen('/thread/typing/me', (data) => concat(
            after(0, () => userTypingInThread({ threadId: data.payload.threadId }).unwrap()),
            after(1000)
        ),
            { mode: "ignore" }
        );
    });

    const { data: activeLoan } = useGetLoanByIdQuery(loanId, {
        skip: !loanId
    });

    const onBodyClick = useCallback(async (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        // if target is not an anchor tag
        // we need to prevent default behaviour
        // and navigate to the link
        const linkTarget = event.target as unknown as HTMLLinkElement;
        // if event came from a link
        if (linkTarget.tagName === 'A') {
            if (linkTarget.href?.includes('api/v1/action')) {
                // if it's an action link
                // we need to make a request to get the final redirect url
                // if it's an action link
                // we need to make a request to get the final redirect url

                const redirectToUrl = await getActionLinkRedirectUrl(linkTarget.href);
                if (redirectToUrl) {
                    let finalLink = `${redirectToUrl}&${QUERY_MESSAGE_THREAD_ID}=${threadId}`;
                    // if redirect url has does not have a taskView query param
                    // add message task view
                    if (!finalLink.includes(QUERY_PARAM_TASK_VIEW)) {
                        finalLink = `${finalLink}&${QUERY_PARAM_TASK_VIEW}=${'MESSAGE'}`
                    }
                    // append threadId to the url
                    router.push(finalLink);
                } else {
                    router.push(linkTarget.href.replaceAll("5000", "3000"));
                }
            } else {
                router.push(linkTarget.href.replaceAll("5000", "3000"));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.push])


    const onDeleteDraftMessage = useCallback(async (id: string): Promise<void> => {
        try {
            await deleteDraftMessage(id).unwrap();
            // reset draft message
            reducerDispatch({
                type: MessagesContextReducer.action.setDraftMessage,
                payload: null
            })
            // reset form
            formRef.current?.resetForm();
        } catch (error) {
            console.error(error);
        }
    }, [deleteDraftMessage]);

    const onMessageComposerOpenChange = useCallback(async (open: boolean) => {
        // remove QUERY_PARAM_MESSAGE_LENDER_ID from query
        // when message composer is closed
        if (!open) {
            // if borrower portal remove query params
            if (router.pathname === Route.BORROWER_PORTAL_LENDER) {
                const {
                    [QUERY_MESSAGE_COMPOSE_OPEN]: _,
                    [QUERY_PARAM_MESSAGE_LENDER_ID]: __,
                    ...query } = router.query;
                router.replace({
                    pathname: router.pathname,
                    query: query
                },
                    undefined,
                    {
                        scroll: false
                    });
            }
        }
        reducerDispatch({
            type: MessagesContextReducer.action.setEditorFormValues,
            payload: null
        })
        // toggle message composer open
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageComposerOpen,
            payload: open
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageWindowState,
            payload: {
                dialog: "CLOSED",
                loanId: null,
                isRecipientOpen: true
            }
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setNeedsListStagedElements,
            payload: []
        })

    }, [router])

    const onCancelSingleFile = useCallback((listFile: ListFile) => {
        listFile.abortController.abort();
        toast.error('File upload cancelled');
    }, [])

    const onNavigateToThread = useCallback((thread: MessageThreadDto) => {
        // set pathname to borrower portal if we are on borrower portal
        // otherwise lender messaging
        let pathname: string = Route.SINGLE_LOAN_MESSAGES;
        if (router.pathname === Route.BORROWER_PORTAL_LENDER) {
            pathname = Route.BORROWER_PORTAL_LENDER;
        }
        // remove search label text and message id from query
        // if we don't have a thread we need to remove the thread id from the query

        const {
            [QUERY_MESSAGE_SEARCH_LABEL]: _,
            [QUERY_MESSAGE_SEARCH_TEXT]: __,
            [QUERY_PARAM_MESSAGE_LENDER_ID]: ___,
            ...rest
        } = router.query;
        router.push({
            pathname,
            query: {
                ...rest,
                [QUERY_MESSAGE_THREAD_ID]: thread?.id ?? undefined,
                [QUERY_PARAM_LOAN_ID]: loanId,
                [QUERY_MESSAGE_ID]: thread?.lastUnreadMessageId ? thread?.lastUnreadMessageId : undefined,
            }
        },
            undefined,
            {
                scroll: false
            });
        formRef.current?.saveDraft();
        // reset reply to message
        reducerDispatch({
            type: MessagesContextReducer.action.setReplyToMessage,
            payload: null
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageWindowState,
            payload: {
                dialog: "CLOSED",
                loanId: null,
                isRecipientOpen: true
            }
        })
        // reset unread filter
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterUnread,
            payload: false
        })
        // reset draft filter
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterDraft,
            payload: false
        })
    }, [loanId, router])

    const onSendMessageConfirm = useCallback(async (message: MessageSendDto) => {
        const { elements: assignedElements, users: sentToUsers, sharedWithUsers: assignedToUsers } = state.assignDialogElements;
        try {
            reducerDispatch({
                type: MessagesContextReducer.action.setIsSubmitting,
                payload: true
            })
            const hasLinks = doesTextHaveActionLinks(String(message.body));
            const lMessageBody = cleanMessageBody(message.body);
            const result = await sendMessage({
                ...message,
                body: lMessageBody,
                hasLinks
            }).unwrap();

            editorRef.current.setContent("")
            // reset reply to message
            reducerDispatch({
                type: MessagesContextReducer.action.setReplyToMessage,
                payload: null
            })
            editorRef.current?.focus();
            setUploadedFiles(prevState => {
                const newState = { ...prevState };
                delete newState['new-thread'];
                return ({
                    ...newState
                })
            });
            // setDraftMessage
            formRef.current?.resetForm();

            reducerDispatch({
                type: MessagesContextReducer.action.setEditorFormValues,
                payload: null
            })
            reducerDispatch({
                type: MessagesContextReducer.action.setDraftMessage,
                payload: null
            })
            reducerDispatch({
                type: MessagesContextReducer.action.setAssignDialogElements,
                payload: {
                    elements: [],
                    users: [],
                    sharedWithUsers: [],
                    isOpen: false,
                    message: null
                }
            })
            reducerDispatch({
                type: MessagesContextReducer.action.setMessageComposerOpen,
                payload: false
            })
            reducerDispatch({
                type: MessagesContextReducer.action.setMessageWindowState,
                payload: {
                    dialog: "CLOSED",
                    loanId: null,
                    isRecipientOpen: true
                }
            })
            if (assignedToUsers.length > 0 &&
                assignedElements.length > 0) {
                const itemTitle = assignedElements.length === 1 ? assignedElements[0].title : `${assignedElements.length} items`;
                const userName = assignedToUsers.length === 1 ? getUserDisplayName(assignedToUsers[0]) : `${assignedToUsers.length} users`;
                const message = `${itemTitle} sent and assigned to ${userName}`;
                toast.success(message);
            } else if (sentToUsers.length > 0) {
                const userName = sentToUsers.length === 1 ? getUserDisplayName(sentToUsers[0]) : `${sentToUsers.length} users`;
                // toast.success(`Message sent to ${userName}`);
            } else {
                // toast.success('Message sent')
            }
            trigger('/messages/submitted')
            // navigate to message thread
            // if message dialog is not open
            if (router.pathname === Route.SINGLE_LOAN_MESSAGES || userState.isBorrower) {
                onNavigateToThread(result.messageThread);
            }
            editorRef.current.focus();
            return result;
        } catch (error) {
            console.log({ error })
            toast.error(error?.data?.message || 'Failed to send message');
            return null
        } finally {
            reducerDispatch({
                type: MessagesContextReducer.action.setIsSubmitting,
                payload: false
            })
        }
    }, [onNavigateToThread, router.pathname, sendMessage, state.assignDialogElements, userState.isBorrower])

    const onMuteThreadChange = useCallback(async (threadId: string, isMuted: boolean): Promise<void> => {
        try {
            if (isMuted) {
                await muteThread(threadId).unwrap();
            } else {
                await unmuteThread(threadId).unwrap();
            }
            toast.success(<UndoToast
                onUndo={() => onMuteThreadChange(threadId, !isMuted)}
                message={`Conversation ${isMuted ? 'muted' : 'unmuted'}`} />,
                {
                    autoClose: 5000
                }
            );
        } catch (error) {
            console.error(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendDraftMessage, messagesScrollAreaRef.current]);

    const onSendDraftMessage = useCallback(async (message: MessageSendDto): Promise<MessageDtoExtended> => {
        try {
            const hasLinks = doesTextHaveActionLinks(message.body);
            return await sendDraftMessage({
                ...message,
                hasLinks
            }).unwrap();
        } catch (error) {
            console.error(error);
            return null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendDraftMessage, messagesScrollAreaRef.current]);

    const onUpdateDraftMessage = useCallback(async (draftedMessageId: string, message: MessageSendDto): Promise<MessageDtoExtended> => {
        try {
            const hasLinks = doesTextHaveActionLinks(message.body);
            return await updateDraftMessage({
                draftId: draftedMessageId, data: {
                    ...message,
                    hasLinks
                }
            }).unwrap();
        } catch (error) {
            console.error(error);
            return null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDraftMessage, messagesScrollAreaRef.current]);

    const onSendMessage = useCallback(async (message: MessageSendDto, options: { skipAssign: boolean } = { skipAssign: false }): Promise<MessageDtoExtended> => {
        reducerDispatch({
            type: MessagesContextReducer.action.setIsSubmitting,
            payload: true
        })
        const messageLoan = await dispatch(loanApi.endpoints.getLoanById.initiate(message.contextId)).unwrap();
        const messageThread = loanThreadsData?.find(thread => thread.id === message.messageThreadId);
        // if message has document attachments
        // and thread is public we should show a warning dialog
        // before sending the message
        // public means we are sending to an existing thread with locked:false
        // or we are sending a new message without recipients selected
        // or we are sending a new message to lenders recipients only
        if (message.attachments.length > 0) {
            const lenderRecipients = messageLoan.loanRoles.filter(loanRole => message.toUserIds.includes(loanRole.user.id) && !isRoleABorrower(loanRole.role)).map(loanRole => loanRole.user.id);
            const isPublicThread = (!message.messageThreadId && message.toUserIds.length === 0 && !userState.isLender) ||
                (!message.messageThreadId && lenderRecipients.length > 0) ||
                (messageThread && !messageThread.locked && userState.isBorrower) ||
                (messageThread && !messageThread.locked && messageThread.general && userState.isLender) ||
                (messageThread && !messageThread.locked && userState.isLender && !messageThread.lendingTeam)

            if (isPublicThread) {
                reducerDispatch({
                    type: MessagesContextReducer.action.setConfirmSendingAttachmentsToPublicThreadDialogOpen,
                    payload: message
                })
                reducerDispatch({
                    type: MessagesContextReducer.action.setIsSubmitting,
                    payload: false
                })
                return;
            }
        }
        // if we are replying to a thread
        // first check if recipient is a borrower or lender
        // if it's a borrower and does not exist in the thread users
        // we need to show warning that the user is not part of the thread
        // and will have access to all messages in the thread
        if (messageThread && !messageThread.general && message.toUserIds.length > 0) {
            const borrowerRecipients = messageLoan.loanRoles
                .filter(loanRole => message.toUserIds.includes(loanRole.user.id) && isRoleABorrower(loanRole.role))
                .map(loanRole => loanRole.user.id);

            const threadUserIds = messageThread.users.map(user => user.user.id);
            if (borrowerRecipients.length && !threadUserIds.some(toId => borrowerRecipients.includes(toId))) {
                reducerDispatch({
                    type: MessagesContextReducer.action.setConfirmSendPrivateThreadDialogOpen,
                    payload: message
                })
                reducerDispatch({
                    type: MessagesContextReducer.action.setIsSubmitting,
                    payload: false
                })
                return;
            }
        }
        if (userState.isLender) {
            const invisibleOrNotOnLoanUsers: ElementsSharingDialogProps['props']['shareWithUsers'] = []
            // check if we are sending message to borrowers and lenders that are not visible to each other
            // if yes then show a confirmation dialog to make them visible
            const mostRecentActiveLoan = await dispatch(loanApi.endpoints.getLoanById.initiate(message.contextId)).unwrap();
            const isSendingToInvisibleLendersAndBorrowers = checkIfSendingToInvisibleLendersAndBorrowers(
                message.toUserIds,
                mostRecentActiveLoan,
                userState.user,
                messageThread);

            if (isSendingToInvisibleLendersAndBorrowers) {
                const invisibleLenderRoles: ElementsSharingDialogProps['props']['shareWithUsers'] = mostRecentActiveLoan.loanRoles
                    .filter(loanRole => !isRoleABorrower(loanRole.role) && (message.toUserIds.includes(loanRole.user.id) || userState.user.id === loanRole.user.id) &&
                        !loanRole.visibleToBorrower)
                    .map(loanRole => ({
                        ...loanRole.user,
                        isVisibleToBorrower: false,
                        isOnLoan: true,
                        isAssignedElements: true,
                        role: loanRole.role
                    }));
                invisibleOrNotOnLoanUsers.push(...invisibleLenderRoles);
            }

            // if user is a lender
            // check if users have the elements assigned
            // if not then show dialog to assign elements to users
            // or send message without assigning
            const elementIdsInMessageBody = extractFormElementIds(message.body);
            const mostRecentLoanElements = await dispatch(packageApi.endpoints.getLoanElements.initiate({ id: message.contextId, view: 'CONVENTIONAL' }, {
                subscribe: true,
                forceRefetch: true
            },)).unwrap();
            const filteredElements = mostRecentLoanElements.list
                .filter((element) => elementIdsInMessageBody.includes(element.id))
            const borrowerUserIds = recipientsUsers.filter(user => isRoleABorrower(user.loggedCompanyRole))
                .map(user => user.id);
            const toBorrowerUserIds = message.toUserIds.filter(userId => borrowerUserIds.includes(userId));
            const notOnLoanUserIds = [...message.toUserIds, userState.user.id].filter(userId => !messageLoan.loanRoles.some(loanRole => loanRole.user.id === userId));
            recipientsUsers
                .filter(user => notOnLoanUserIds.includes(user.id))
                .forEach(user => {
                    invisibleOrNotOnLoanUsers.push({
                        ...user,
                        isOnLoan: false,
                        isVisibleToBorrower: true,
                        role: user.loggedCompanyRole,
                        isAssignedElements: true
                    })
                });

            const userIdsWithNotSharedElements = getUsersWithNotSharedElements(filteredElements, toBorrowerUserIds);

            if (userIdsWithNotSharedElements.length > 0) {
                // if user id does not exist on invisibleOrNotOnLoanUsers
                // add them to the list
                userIdsWithNotSharedElements.forEach(userId => {
                    if (!invisibleOrNotOnLoanUsers.some(user => user.id === userId)) {
                        const user = recipientsUsers.find(user => user.id === userId)
                        if (user) {
                            invisibleOrNotOnLoanUsers.push({
                                ...user,
                                isOnLoan: true,
                                isVisibleToBorrower: true,
                                role: user.companyRole,
                                isAssignedElements: !isRoleABorrower(user.companyRole)
                            })
                        }
                    }
                })
            }
            if (invisibleOrNotOnLoanUsers.length > 0 && !options.skipAssign) {
                const users = invisibleOrNotOnLoanUsers.filter((user) => message?.toUserIds.includes(user.id) || user.id === userState.user.id)
                const userIds = users.map(user => user.id);
                const unassignedElements = filteredElements.filter(element => !element.sharedInfo.some(sharedInfo => userIds.includes(sharedInfo.sharedWithUser.id)));
                reducerDispatch({
                    type: MessagesContextReducer.action.setAssignDialogElements,
                    payload: {
                        message,
                        elements: unassignedElements,
                        sharedWithUsers: [],
                        isOpen: true,
                        users
                    }
                })
                reducerDispatch({
                    type: MessagesContextReducer.action.setIsSubmitting,
                    payload: false
                })
                return;
            }
        }
        return onSendMessageConfirm(message);
    }, [dispatch, loanThreadsData, onSendMessageConfirm, recipientsUsers, userState.isBorrower, userState.isLender, userState.user]);

    const onAfterAssignElementsConfirm = useCallback(async (sharedWithUsers: AppUserDTO2[]) => {
        // due to element list not getting invalidated by the time we send the message
        // we are going to pass a skipAssign flag to skip the assign dialog
        await onSendMessage(state.assignDialogElements.message, {
            skipAssign: true
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setAssignDialogElements,
            payload: {
                elements: [],
                users: [],
                sharedWithUsers: [],
                message: null,
                isOpen: false,
            }
        })
    }, [onSendMessage, state.assignDialogElements.message])

    const onAssignDialogOpenChange = useCallback((open: boolean) => {
        if (!open) {
            reducerDispatch({
                type: MessagesContextReducer.action.setAssignDialogElements,
                payload: {
                    elements: state.assignDialogElements.elements,
                    sharedWithUsers: state.assignDialogElements.sharedWithUsers,
                    users: state.assignDialogElements.users,
                    message: state.assignDialogElements.message,
                    isOpen: false
                }
            })
        }
    }, [state.assignDialogElements.elements, state.assignDialogElements.message, state.assignDialogElements.sharedWithUsers, state.assignDialogElements.users])

    const onSendMessageClickConfirm = useCallback(async (elements: FormElementV2ResponseDtoExtended[], loanId: string) => {
        // if we are on the package page we need to open the message window
        if (router.pathname === Route.SINGLE_LOAN || router.pathname === Route.HOME) {
            reducerDispatch({
                type: MessagesContextReducer.action.setMessageWindowState,
                payload: {
                    dialog: 'OPEN',
                    loanId: loanId,
                    isRecipientOpen: true
                }
            })
        }
        // reset reply to 
        reducerDispatch({
            type: MessagesContextReducer.action.setReplyToMessage,
            payload: null
        })

        // if we are on borrower portal
        if (router.pathname === Route.BORROWER_PORTAL_LENDER) {
            // open messages task view 
            // and set the form elements id
            const {
                [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: _,
                [QUERY_PARAM_LOAN_ID]: __,
                [QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID]: ___,
                [QUERY_PARAM_NEEDS_LIST_DIALOG]: ____,
                [QUERY_PARAM_FORM_ELEMENT_IDS]: ______,
                [QUERY_MESSAGE_ID]: ________,
                [QUERY_MESSAGE_THREAD_ID]: _________,
                ...query } = router.query;
            const [firstElement] = elements
            const newQuery = {
                ...query,
                [QUERY_MESSAGE_COMPOSE_OPEN]: 1,
                [QUERY_PARAM_FORM_ELEMENT_IDS]: elements.map(element => element.id).join(','), // set the form element ids
            }
            if (firstElement) {
                newQuery[QUERY_PARAM_LOAN_ID] = firstElement.loanId;
                newQuery[QUERY_PARAM_FORM_ELEMENT_ID] = firstElement.id;
                newQuery[QUERY_PARAM_TASK_VIEW] = 'MESSAGE';
                const elementLoan = loansData.find(loan => loan.id === firstElement.loanId);
                if (elementLoan) {
                    const leadLender = elementLoan.loanRoles.find(loanRole => loanRole.role === 'LEAD_LENDER');
                    newQuery[QUERY_PARAM_MESSAGE_LENDER_ID] = leadLender.user.id;
                }
            } else if (loansData?.length > 1) {
                newQuery[QUERY_PARAM_SELECT_BORROWER_PORTAL_MESSAGES_NEW_LOANS_DIALOG] = "1";
            } else if (loansData?.length === 1) {
                newQuery[QUERY_PARAM_LOAN_ID] = loansData[0].id;
                const leadLender = loansData[0].loanRoles.find(loanRole => loanRole.role === 'LEAD_LENDER');
                newQuery[QUERY_PARAM_MESSAGE_LENDER_ID] = leadLender.user.id;
                newQuery[QUERY_PARAM_TASK_VIEW] = 'MESSAGE';

            }
            await router.push({
                pathname: Route.BORROWER_PORTAL_LENDER,
                query: newQuery
            },
                undefined,
                {
                    scroll: false
                });
        }
        // if we have elements we need to generate digest
        if (elements.length > 0) {
            const elementsLoans = elements.map(element => element.loanId);
            const uniqueLoans = [...new Set(elementsLoans)];
            const htmlForLoans = await Promise.all(uniqueLoans.map(async (loanId) => {
                const loanElements = elements.filter(element => element.loanId === loanId);
                const html = await generateDynamicNeedsListBody({
                    loanId,
                    infoIds: loanElements.map(({ id }) => id),
                    userIds: []
                }).unwrap();

                return `<ul class="list-none">
                            <li>
                            ${html}
                            </li>
                        </ul>`
            })).then(htmlForLoans => htmlForLoans.join(''));

            const finalHtml = `<p></p><p data-dynamic-needs-list="true">Below Auto Generated</p>${htmlForLoans}`
            reducerDispatch({
                type: MessagesContextReducer.action.setEditorFormValues,
                payload: {
                    body: finalHtml,
                }
            })
            editorRef.current?.setContent(finalHtml);
        }
        editorRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loansData?.length, router.push, router.query])

    const onSendMessageClick = useCallback(async (elements: FormElementV2ResponseDtoExtended[], args: { recipients: string[], loanId: string } = { recipients: [], loanId: null }) => {
        // if we have extra options
        // and we have recipients
        // we need to set the recipients
        if (args.recipients.length > 0) {
            reducerDispatch({
                type: MessagesContextReducer.action.setEditorFormValues,
                payload: {
                    recipients: args.recipients
                }
            })
        }

        // Scenario 1 first we need to check if there is previous elements selected
        // if none are selected proceed to send message
        const messageBody = formRef.current?.getValues("body");
        const elementsIds = extractFormElementIds(messageBody);
        const existingElements = loanElements.list.filter(element => elementsIds.includes(element.id));
        // and stop using needsListElements from reducer
        if (existingElements.length === 0) {
            // set the needs list elements
            onSendMessageClickConfirm(elements, args.loanId);
        }
        // Scenario 2 if there are elements selected
        // we need to prompt the user to confirm if they want to start new message
        // or append to the existing message
        else {
            reducerDispatch({
                type: MessagesContextReducer.action.setNeedsListStagedElements,
                payload: elements
            })
        }
    }, [loanElements.list, onSendMessageClickConfirm])

    const onFilterLabelChange = (label: MessageLabel) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterLabel,
            payload: label
        })
    }

    const onFilesDrop = useCallback(async (files: File[], threadId: string): Promise<string[]> => {
        const otherFiles = files.filter((file) => !isZipFile(file));
        // get zip files to extract and get files from
        const zipFilePromises = files.filter(isZipFile)
            .map(async (file) => {
                try {
                    const { files: extractedFiles } = await getFoldersAndFiles(file);
                    return extractedFiles;
                } catch (error) {
                    toast.error(`Failed to extract ${file.name} will be uploaded as zip.`);
                    return [file];
                }
            });
        const zipFiles = await Promise.all(zipFilePromises);

        const allFiles = [
            ...otherFiles,
            ...zipFiles.flat()
        ];
        const listFiles: Record<string, ListFile> = allFiles.reduce((acc, file) => {
            const id = Math.random().toString(36).substring(7);
            const listFile: ListFile = {
                id,
                file,
                abortController: new AbortController(),
                progress: 0,
                status: 'uploading',
                documentName: file.name,
                documentId: ''
            }
            return {
                ...acc,
                [listFile.id]: listFile
            }
        }, {});
        setUploadedFiles(prevState => ({
            ...prevState,
            ['new-thread']: {
                ...prevState['new-thread'],
                ...listFiles
            }
        }))
        const uploadAnonymousPromises = Object.values(listFiles).map(async (listFile) => {
            try {
                const uploadUrlRequest = getAnonymousUploadUrl({ name: listFile.file.name })
                listFile.abortController.signal.addEventListener('abort', () => {
                    uploadUrlRequest.abort();
                }, {
                    once: true
                });
                const uploadUrl = await uploadUrlRequest.unwrap();
                if (uploadUrl.url) {
                    const uploadRequest = uploadFile({
                        url: uploadUrl.url,
                        data: listFile.file,
                        headers: uploadUrl.providerType === "SHAREPOINT" ? {
                            "Content-range": `bytes 0-${listFile.file.size - 1}/${listFile.file.size}`,
                        } : undefined,
                        onUploadProgress: (progress) => {
                            setUploadedFiles(prevState => {

                                if (prevState[listFile.id]) {
                                    return ({
                                        ...prevState,
                                        ['new-thread']: {
                                            ...prevState['new-thread'],
                                            [listFile.id]: {
                                                ...prevState['new-thread'][listFile.id],
                                                progress
                                            }
                                        }
                                    })
                                }

                                return prevState
                            });
                        }
                    });
                    listFile.abortController.signal.addEventListener('abort', () => {
                        uploadRequest.abort();
                    }, {
                        once: true
                    });
                    await uploadRequest.unwrap();
                    const postRequest = postUploadUrl(uploadUrl);
                    listFile.abortController.signal.addEventListener('abort', () => {
                        postRequest.abort();
                    }, {
                        once: true
                    });
                    const response = await postRequest.unwrap();
                    setUploadedFiles(prevState => ({
                        ...prevState,
                        ['new-thread']: {
                            ...prevState['new-thread'],
                            [listFile.id]: {
                                ...prevState['new-thread']?.[listFile.id],
                                status: 'success',
                                documentId: response.id
                            }
                        }
                    }));
                    return response.id;
                }

            } catch (error) {
                if (error.name !== 'AbortError') {
                    setUploadedFiles(prevState => ({
                        ...prevState,
                        ['new-thread']: {
                            [listFile.id]: {
                                ...prevState['new-thread'][listFile.id],
                                status: 'error'
                            }
                        }
                    }));
                } else {
                    setUploadedFiles(prevList => {
                        const newListFiles = { ...prevList };
                        delete newListFiles['new-thread'][listFile.id];
                        return newListFiles;
                    });
                }

                return null
            }
        });

        const documentsIds = await Promise.all(uploadAnonymousPromises);
        return documentsIds.filter(Boolean);
    }, [getAnonymousUploadUrl, postUploadUrl, uploadFile])

    const onStageSingleFileForDelete = (listFile: ListFile) => {
        setStagedListFile({
            operation: 'DELETE',
            data: listFile,
            message: null
        })
    }

    const onStageSingleFileForRename = (listFile: ListFile) => {
        setStagedListFile({
            operation: 'RENAME',
            data: listFile,
            message: null
        })
    }

    const onRenameStagedFile = async (listFile: ListFile, newName: string) => {
        try {
            const promise = updateDocument({
                id: listFile.documentId,
                name: newName,
            });
            await promise.unwrap();
            setUploadedFiles(prevState => ({
                ...prevState,
                ['new-thread']: {
                    ...prevState['new-thread'],
                    [listFile.id]: {
                        ...prevState['new-thread'][listFile.id],
                        documentName: newName
                    }
                }
            }));
            toast.success('File renamed');
        } catch (error) {
            toast.error('Error renaming file');
        }
    }

    const onDeleteStagedFile = async (listFile: ListFile) => {
        try {
            const promise = updateDocument({
                id: listFile.documentId,
                name: listFile.documentName,
                // trash: true
            }
            );
            await promise.unwrap();

            setUploadedFiles(prevState => {
                const newListFiles = { ...prevState };
                delete newListFiles['new-thread'][listFile.id];
                return newListFiles;
            });
            toast.success('File deleted');
            router.replace({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    [QUERY_DOCUMENT_PREVIEW_ID]: undefined,
                }
            },
                undefined,
                {
                    scroll: false
                });
        } catch (error) {
            toast.error('Error deleting file');
        }
    }

    const onDeleteMessageConfirm = (message: MessageDtoExtended) => {
        deleteMessage({ messageId: message.id }).unwrap();
    }

    const onDeleteMessage = useCallback(async (message: MessageDtoExtended) => {
        setStagedListFile({
            operation: "DELETE",
            data: null,
            message
        })
    }, [])

    const onFileClick = useCallback((listFile: ListFile) => {
        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_DOCUMENT_PREVIEW_ID]: listFile.documentId,
                [QUERY_PARAM_VIEWER_ACTIONS_DISABLED]: 1
            }
        },
            undefined,
            {
                scroll: false
            })
    }, [router])

    const onForwardMessage = useCallback(async (message: MessageDtoExtended) => {
        try {
            const result = await forwardMessage({
                messageId: message.id
            }).unwrap();
            toast.success('Message forwarded');
            return result;
        } catch (error) {
            toast.error('Error forwarding message');
            return null;
        }
    }, [forwardMessage])

    const onClearThreadFiles = useCallback((threadId?: string) => {
        setUploadedFiles(prevState => {
            const newState = { ...prevState };
            delete newState['new-thread'];
            return newState;
        })
    }, [])

    const onResetStagedListFile = () => {
        setStagedListFile(stagedFileInitialState);
    }


    const onOpenInWindow = useCallback((message: MessageDtoExtended) => {
        // open new popup window
        window.open(message.originalMessageContentUrl, '_blank');
    }, [])



    const onReplyToMessage = useCallback((message: MessageDtoExtended) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setReplyToMessage,
            payload: message
        })
    }, [])

    const onSendMessageConfirmShare = useCallback(async (message: MessageSendDto) => {
        await onSendMessageConfirm(message);
        reducerDispatch({
            type: MessagesContextReducer.action.setConfirmSendPrivateThreadDialogOpen,
            payload: null
        })
    }, [onSendMessageConfirm])

    const onCancelShareThread = useCallback(() => {
        reducerDispatch({
            type: MessagesContextReducer.action.setConfirmSendPrivateThreadDialogOpen,
            payload: null
        })
    }, [])

    const onSendMessageConfirmAttachments = useCallback((message: MessageSendDto) => {
        onSendMessageConfirm(message);
        reducerDispatch({
            type: MessagesContextReducer.action.setConfirmSendingAttachmentsToPublicThreadDialogOpen,
            payload: null
        })
    }, [onSendMessageConfirm])

    const onConfirmDoNotShareThread = useCallback(async (message: MessageSendDto) => {
        await onSendMessageConfirm({ ...message, shareOldHistory: false });
        reducerDispatch({
            type: MessagesContextReducer.action.setConfirmSendPrivateThreadDialogOpen,
            payload: null
        })
    }, [onSendMessageConfirm])

    const onSendMessageCancelAttachments = useCallback(() => {
        reducerDispatch({
            type: MessagesContextReducer.action.setConfirmSendingAttachmentsToPublicThreadDialogOpen,
            payload: null
        })
    }, [])

    const activeMessageId = router.query[QUERY_MESSAGE_ID] as string;
    const messageInQuery = !!activeMessageId;
    const onFilterQueryChange = useCallback((query: string) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterQuery,
            payload: query
        })
        // if we have message id in the query we need to remove it
        if (messageInQuery) {
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    [QUERY_MESSAGE_ID]: undefined
                }
            },
                undefined,
                {
                    scroll: false
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageInQuery])

    const onSetActiveLoan = useCallback((loan: BasicLoanDto) => {
        // reset label filter
        onFilterLabelChange(null);
        // reset unread filter
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterUnread,
            payload: false
        })
        // reset draft filter
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterDraft,
            payload: false
        })
        // reset search query
        onFilterQueryChange('');
        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_PARAM_LOAN_ID]: loan.id,
                [QUERY_MESSAGE_THREAD_ID]: undefined,
                [QUERY_MESSAGE_ID]: undefined
            }
        },
            undefined,
            {
                scroll: false
            });
    }, [onFilterQueryChange, router])

    const onNavigateToDraftMessage = useCallback(async (message: MessageDto) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageWindowState,
            payload: {
                dialog: 'OPEN',
                loanId: loanId,
                isRecipientOpen: true
            }
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageComposerOpen,
            payload: false
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setDraftMessage,
            payload: message
        })
        const recipients = message.toRecipients
            ? message.toRecipients.map(user => user.to.id)
            : [];
        const formValues = {
            subject: message.subject,
            recipients: message.toRecipients ? message.toRecipients.map(user => user.to.id) : [],
            isRecipientOpen: recipients.length > 0,
            isSubjectOpen: !!message.subject,
            draftedMessageId: message.id,
            body: message.body,
            attachments: message.documents ?? []
        }
        reducerDispatch({
            type: MessagesContextReducer.action.setEditorFormValues,
            payload: formValues
        })
        const documents = await Promise.all(message.documents.map(async id => dispatch(documentApi.endpoints.getDocumentWithDownloadUrl.initiate({ id }, { subscribe: true })).unwrap()));

        setUploadedFiles(prevState => {
            const newState = { ...prevState };
            newState['new-thread'] = documents.reduce((acc, document) => {
                const id = Math.random().toString(36).substring(7);
                acc[id] = {
                    id,
                    documentName: document.name,
                    status: 'success',
                    progress: 100,
                    documentId: document.id
                }
                return acc;
            }, {});
            return newState;
        })
    }, [dispatch, loanId])

    const onAddDigest = useCallback(async (userIds: string[]) => {
        setTransition(() => {
            reducerDispatch({
                type: MessagesContextReducer.action.setMessageWindowState,
                payload: {
                    dialog: 'OPEN',
                    loanId: loanId,
                    isRecipientOpen: true
                }
            })
        })
        const loadingHtml = `<p></p><p data-dynamic-needs-list="true">Below Auto Generated</p><p></p><digest-skeleton-component></digest-skeleton-component>`
        reducerDispatch({
            type: MessagesContextReducer.action.setEditorFormValues,
            payload: {
                body: loadingHtml
            }
        })

        const html = await dispatch(taskApi.endpoints.tasksForLoanAndUserAsHtml.initiate({
            loanId,
            userId: userIds[0]
        }, {
            forceRefetch: true
        })).unwrap();
        formRef.current?.setFormValues({
            recipients: userIds
        })
        editorRef.current?.setContent(`<p></p><p data-dynamic-needs-list="true">Below Auto Generated</p>${html}`);
        reducerDispatch({
            type: MessagesContextReducer.action.setAddDigestWarningDialogUsers,
            payload: []
        })
        // focus on the editor
        editorRef.current?.focus();
    }, [dispatch, loanId])

    const onConfirmAddDigest = useCallback(async (userIds: string[]) => {
        // clear uploading files
        // then add digest
        reducerDispatch({
            type: MessagesContextReducer.action.setAddDigestWarningDialogUsers,
            payload: []
        })
        setUploadedFiles({});
        onAddDigest(userIds);
    }, [onAddDigest])

    const onDigestClick = useCallback((userIds: string[]) => {
        // if there is content in the editor
        // or we have staged files
        if (!!editorRef.current?.getContent() || Object.keys(uploadedFiles).length > 0) {
            reducerDispatch({
                type: MessagesContextReducer.action.setAddDigestWarningDialogUsers,
                payload: userIds
            })
        } else {
            onAddDigest(userIds);
        }
    }, [onAddDigest, uploadedFiles])

    const onNavigateToMessage = useCallback((message: MessageDto) => {
        // clear search query
        onFilterQueryChange('');
        onFilterLabelChange(null);
        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_MESSAGE_THREAD_ID]: message.messageThread.id,
                [QUERY_MESSAGE_ID]: message.id,
                [QUERY_PARAM_LOAN_ID]: loanId,
                [QUERY_MESSAGE_SEARCH_TEXT]: state.filterQuery,
                [QUERY_MESSAGE_SEARCH_LABEL]: state.filterLabel
            }
        },
            undefined,
            {
                scroll: false
            });
    }, [loanId, onFilterQueryChange, router, state.filterLabel, state.filterQuery])

    const onFilterOrderChange = useCallback((order: 'ASC' | 'DESC') => {
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterOrder,
            payload: order
        })
    }, [])

    const onEditorReady = useCallback(() => {
        // if we have content set it
        if (state.editorFormValues) {
            formRef.current?.setFormValues(state.editorFormValues)
            editorRef.current.setContent(state.editorFormValues?.body ?? "");
        }
        // focus on the editor
        editorRef.current?.focus();
    }, [state.editorFormValues])

    const onViewOriginalHtml = useCallback((message: MessageDtoExtended) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setOriginalMessageToViewAsHtml,
            payload: message
        })
    }, [])

    const onFilterUnreadChange = useCallback((unread: boolean) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterUnread,
            payload: unread
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterDraft,
            payload: false
        })
    }, [])

    const onFilterDraftChange = useCallback(() => {
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterDraft,
            payload: !state.filterDraft
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setFilterUnread,
            payload: false
        })
    }, [state.filterDraft])

    const onCancelSendMessage = useCallback(() => {
        reducerDispatch({
            type: MessagesContextReducer.action.setIsSubmitting,
            payload: false
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setReplyToMessage,
            payload: null
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setDraftMessage,
            payload: null
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setNeedsListStagedElements,
            payload: []
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageComposerOpen,
            payload: false
        })
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageWindowState,
            payload: {
                dialog: 'CLOSED',
                loanId: null,
                isRecipientOpen: true
            }
        })
    }, [])

    const onMinimizeWindowStateChange = useCallback((windowState) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageWindowState,
            payload: {
                dialog: windowState,
                loanId: state.messageWindowState.loanId,
                isRecipientOpen: state.messageWindowState.isRecipientOpen
            }
        })
        if (["MAXIMIZED", "OPEN"].includes(windowState)) {
            // focus on the editor
            editorRef.current?.focus();
        } else if (windowState === "CLOSED") {
            onMessageComposerOpenChange(false);
            // reset the files
            setUploadedFiles({});
            formRef.current?.closeRecipient()
            formRef.current?.saveDraft()
            onCancelSendMessage()
        }

    }, [onCancelSendMessage, onMessageComposerOpenChange, state.messageWindowState.loanId])

    const onConfirmAppendNeedsElements = () => {
        const messageBody = formRef.current?.getValues("body");
        const elementsIds = extractFormElementIds(messageBody);
        const existingElements: FormElementV2ResponseDtoExtended[] = loanElements.list.filter(element => elementsIds.includes(element.id));
        const elements = [...existingElements, ...state.needsListStagedElements];
        const finalElements = dedupedElements(elements);
        reducerDispatch({
            type: MessagesContextReducer.action.setNeedsListStagedElements,
            payload: []
        })
        onSendMessageClickConfirm(finalElements, finalElements[0].loanId);
    }

    const onCancelMessageAboutElements = () => {
        reducerDispatch({
            type: MessagesContextReducer.action.setNeedsListStagedElements,
            payload: []
        })
    }

    const onSetDraftMessage = useCallback((message: MessageDtoExtended) => {
        reducerDispatch({
            type: MessagesContextReducer.action.setDraftMessage,
            payload: message
        })
    }, [])

    const onConfirmStartNewNeedsListMessage = () => {
        reducerDispatch({
            type: MessagesContextReducer.action.setNeedsListStagedElements,
            payload: []
        })
        onSendMessageClickConfirm(state.needsListStagedElements, state.needsListStagedElements[0].loanId);
    }
    const onCancelAddDigest = () => {
        reducerDispatch({
            type: MessagesContextReducer.action.setAddDigestWarningDialogUsers,
            payload: []
        })
    }

    const onNoAccessOpenChange = async () => {
        const {
            [NO_ACCESS_MODAL_ID]: _,
            ...rest
        } = router.query;
        router.replace({
            pathname: router.pathname,
            query: rest
        })
    }

    const onNoAccessAskMyLenderClick = () => {
        onSendMessageClick([], {
            recipients: [],
            loanId: router.query[QUERY_PARAM_LOAN_ID] as string,
        })
    }



    const onComposeNewMessageClick = useCallback(async () => {
        reducerDispatch({
            type: MessagesContextReducer.action.setMessageWindowState,
            payload: {
                dialog: 'OPEN',
                loanId: loanId,
                isRecipientOpen: false
            }
        })
    }, [loanId])

    useEffect(() => {
        if (composeMessageOpenQueryParam) {
            reducerDispatch({
                type: MessagesContextReducer.action.setMessageComposerOpen,
                payload: true
            })
        }
    }, [composeMessageOpenQueryParam])


    const value = useMemo(() => ({
        messageComposerOpen: state.messageComposerOpen,
        loan: activeLoan,
        messageWindowStateDialog: state.messageWindowState.dialog,
        uploadedFiles,
        threads: loanThreadsData,
        loans: loansData,
        filterLabel: state.filterLabel,
        onCancelSingleFile,
        onNavigateToMessage,
        onNavigateToDraftMessage,
        activeThread,
        messagesScrollAreaRef,
        isLoadingLoans,
        editorRef,
        formRef,
        filterUnread: state.filterUnread,
        filterDraft: state.filterDraft,
        draftMessage: state.draftMessage,
        unreadLastMassageMap: unreadMessagesByLoan?.unreadLastMassageMap || {},
        loggedInUserId: userState.user?.id,
        loggedInUserRole: userState.user?.loggedCompanyRole,
        loggedRoleGroup: userState.user?.loggedRoleGroup,
        loansUnreadMap: unreadMessagesByLoan?.unreadMap || {},
        isLoadingThreads: isLoadingThreads,
        replyToMessage: state.replyToMessage,
        unreadMessagesCount: unreadMessagesCount || 0,
        activeMessageId,
        filterQuery: state.filterQuery,
        filterOrder: state.filterOrder,
        isSubmitting: state.isSubmitting,
        onOpenInWindow,
        onFileClick,
        onSendMessageClick,
        onEditorReady,
        onForwardMessage,
        onFilterQueryChange,
        onStageSingleFileForRename,
        onFilterLabelChange,
        onSetDraftMessage,
        onStageSingleFileForDelete,
        onSendMessage,
        onSendDraftMessage,
        onMuteThreadChange,
        onDeleteDraftMessage,
        onUpdateDraftMessage,
        onFilesDrop,
        onNavigateToThread,
        onReplyToMessage,
        onBodyClick,
        onClearThreadFiles,
        onDigestClick,
        onFilterOrderChange,
        onSetActiveLoan,
        onViewOriginalHtml,
        onDeleteMessage,
        onFilterUnreadChange,
        onFilterDraftChange,
        onMessageComposerOpenChange,
        onCancelSendMessage,
        onComposeNewMessageClick
    }), [state.messageComposerOpen, state.messageWindowState.dialog, state.filterLabel, state.filterUnread, state.filterDraft, state.draftMessage, state.replyToMessage, state.filterQuery, state.filterOrder, state.isSubmitting, activeLoan, uploadedFiles, loanThreadsData, loansData, onCancelSingleFile, onNavigateToMessage, onNavigateToDraftMessage, activeThread, isLoadingLoans, unreadMessagesByLoan?.unreadLastMassageMap, unreadMessagesByLoan?.unreadMap, userState.user?.id, userState.user?.loggedCompanyRole, userState.user?.loggedRoleGroup, isLoadingThreads, unreadMessagesCount, activeMessageId, onOpenInWindow, onFileClick, onSendMessageClick, onEditorReady, onForwardMessage, onFilterQueryChange, onSetDraftMessage, onSendMessage, onSendDraftMessage, onMuteThreadChange, onDeleteDraftMessage, onUpdateDraftMessage, onFilesDrop, onNavigateToThread, onReplyToMessage, onBodyClick, onClearThreadFiles, onDigestClick, onFilterOrderChange, onSetActiveLoan, onViewOriginalHtml, onDeleteMessage, onFilterUnreadChange, onFilterDraftChange, onMessageComposerOpenChange, onCancelSendMessage, onComposeNewMessageClick])

    return (
        <MessagesContext.Provider value={value}>
            {props.children}
            {stagedListFile.operation === "DELETE" && stagedListFile.data !== null && <ActionAlertDialog
                ariaLabel='Delete File Dialog'
                message="Are you sure you want to delete this file?"
                open
                onOpenChange={(open) => !open && onResetStagedListFile()}
                onConfirm={() => onDeleteStagedFile(stagedListFile.data)}
            />}
            {!!state.originalMessageToViewAsHtml && <Dialog
                open
                onOpenChange={isOpen => !isOpen && onViewOriginalHtml(null)}
            >
                <Dialog.Content className="h-full w-full sm:max-w-7xl ">
                    <iframe className="h-full w-full scrollbar-stable" srcDoc={originalMessageAsHtml} title="original email" />
                </Dialog.Content>
            </Dialog>}
            {!!state.isConfirmSendPrivateThreadDialogOpen && <ActionAlertDialog
                onCloseButtonClick={() => onCancelShareThread()}
                noCancel
                loading={state.isSubmitting}
                variant="danger"
                ariaLabel='Share message history?'
                title='Share message history?'
                message="Sharing may show this recipient all confidential messages, items, and documents."
                open
                cancelButtonText="Share"
                confirmButtonText="Don’t Share"
                onOpenChange={() => { }}
                onCancel={() => onSendMessageConfirmShare(state.isConfirmSendPrivateThreadDialogOpen)}
                onConfirm={() => onConfirmDoNotShareThread(state.isConfirmSendPrivateThreadDialogOpen)}
            />}
            {state.addDigestWarningDialogUsers.length > 0 && <ActionAlertDialog
                noCancel
                ariaLabel='Send Digest Message'
                message="Adding a digest will clear your message. Are you sure you want to continue?"
                open
                cancelButtonText="Cancel"
                confirmButtonText="Yes, Continue"
                onOpenChange={() => { }}
                onCancel={onCancelAddDigest}
                onConfirm={() => onConfirmAddDigest(state.addDigestWarningDialogUsers)}
            />}
            {!!state.isConfirmSendingAttachmentsToPublicThreadDialogOpen && <ActionAlertDialog
                noCancel
                variant="danger"
                ariaLabel='share documents in a public chat?'
                message="You’re sharing these items in an external thread. Are you sure you want to continue?"
                open
                cancelButtonText="Cancel"
                confirmButtonText="Yes, Continue"
                onOpenChange={() => { }}
                onCancel={() => onSendMessageCancelAttachments()}
                onConfirm={() => onSendMessageConfirmAttachments(state.isConfirmSendingAttachmentsToPublicThreadDialogOpen)}
            />}
            {stagedListFile.operation === "DELETE" && stagedListFile.message !== null && <ActionAlertDialog
                ariaLabel='Delete this message permanently?'
                title="Delete this message permanently?"
                message="Other people in the chat can see that a message was deleted"
                open
                onOpenChange={(open) => !open && onResetStagedListFile()}
                onConfirm={() => onDeleteMessageConfirm(stagedListFile.message)}
                confirmButtonText="Delete"
                cancelButtonText="Cancel"
            />}
            {stagedListFile.operation === "RENAME" && stagedListFile.data !== null && <RenameAlertDialog
                name={getFileNameWithoutExtension(stagedListFile.data?.documentName)}
                onRename={(newName) => onRenameStagedFile(stagedListFile.data, `${newName}.${getExtensionFromFilename(stagedListFile.data?.documentName)}`)}
                open
                onOpenChange={(open) => !open && onResetStagedListFile()}
            />}
            <MinimizeWindow
                title={<Text
                    className="flex-1 flex flex-row items-center gap-1"
                    truncate size="sm" as="div">
                    {!state.draftMessage
                        ? "New Message"
                        : <>
                            {state.draftMessage.subject ?? "New Message"}
                            <Text size="sm" variant="secondary" as="span">(Draft Saved)</Text>
                        </>}
                </Text>}
                onClose={() => onMinimizeWindowStateChange('CLOSED')}
                onMaximize={() => onMinimizeWindowStateChange('OPEN')}
                onMinimize={() => onMinimizeWindowStateChange('MINIMIZED')}
                onFullScreen={() => onMinimizeWindowStateChange('MAXIMIZED')}
                state={state.messageWindowState.dialog}>
                <div className="flex flex-col flex-1 p-2 overflow-hidden group is-message-window">
                    <InlineComposeMessageForm
                        enterSubmit={false}
                        subjectVisible
                        deleteVisible
                        ref={formRef}
                        isRecipientOpen={state.messageWindowState.isRecipientOpen}
                        recipientVisible
                        onDeleteClick={() => onMessageComposerOpenChange(false)}
                        initialValues={{
                            loanId: state.messageWindowState.loanId,
                            recipients: state.editorFormValues?.recipients ?? [],
                        }}
                    />
                </div>
            </MinimizeWindow>
            {state.needsListStagedElements.length > 0 && <ActionAlertDialog
                open={state.needsListStagedElements.length > 0}
                title='Do you want to add these items to your current message or start a new message?'
                message='Adding these items will automatically remove any added text message in the auto generated area.'
                confirmButtonText='Add Items'
                cancelButtonText='Start New Message'
                onConfirm={onConfirmAppendNeedsElements}
                noCancel
                onOpenChange={(open) => {
                    !open && onCancelMessageAboutElements()
                }}
                onCancel={onConfirmStartNewNeedsListMessage}
            />}
            {state.assignDialogElements.isOpen && <ElementsSharingDialog
                loading={state.isSubmitting}
                open={state.assignDialogElements.isOpen}
                shareWithUsers={state.assignDialogElements.users}
                loanId={loanId}
                onConfirm={onAfterAssignElementsConfirm}
                onDialogOpenChange={onAssignDialogOpenChange}
                elements={state.assignDialogElements.elements}
            />}
            {router.query[NO_ACCESS_MODAL_ID] && <NoAccessToFileAlertDialog
                fileName={router.query[QUERY_PARAM_FORM_ELEMENT_TITLE] as string}
                onOpenChange={onNoAccessOpenChange}
                onAskMyLenderClick={userState.isBorrower ? onNoAccessAskMyLenderClick : null}
            />}
        </MessagesContext.Provider>
    );
};

// context hook

export const useMessagesContext = (): MessagesContextValue => {
    return useContext(MessagesContext);
};

function extractFormElementIds(htmlString: string): string[] {
    const regex = /formElementId=([^&"]+)/g;
    let formElementIds = [];
    let match;

    while ((match = regex.exec(htmlString)) !== null) {
        formElementIds.push(match[1]);
    }

    return formElementIds;
}

const getUsersWithNotSharedElements = (elements: FormElementV2ResponseDtoExtended[], userIds: string[]): string[] => {
    if (elements.length === 0) return [];

    const elementIdSharedInfoUserMap = elements.reduce((acc, element) => {
        const sharedInfoUserIds = element.sharedInfo.map(sharedInfo => sharedInfo.sharedWithUser.id);
        return {
            ...acc,
            [element.id]: sharedInfoUserIds
        }
    }, {} as Record<string, string[]>);

    // remove elements that are already assigned to the users
    const filteredUserIds = userIds.filter((userId) => {
        const sharedInfoUserIds = elements.map(element => elementIdSharedInfoUserMap[element.id])
        // user id should exist in all sharedInfoUserIds
        return !sharedInfoUserIds.every(shareIds => shareIds.includes(userId));
    });
    return filteredUserIds;
}

const checkIfSendingToInvisibleLendersAndBorrowers = (toUserIds: string[], loan: LoanDto, loggedInUser: AppUserDTO2, thread?: MessageThreadDto) => {
    const isSenderInvisibleToBorrower = loan.loanRoles.some(loanRole => !loanRole.visibleToBorrower && loanRole.user.id === loggedInUser.id);

    // if we have a thread and there is no recipients and the thread is general and logged in user is invisible
    // return true
    if (thread?.general && isSenderInvisibleToBorrower) {
        return true;
    }

    const invisibleLendersSelected = loan.loanRoles.some(loanRole => !loanRole.visibleToBorrower && toUserIds.includes(loanRole.user.id));
    const borrowersSelected = loan.loanRoles.some(loanRole => isRoleABorrower(loanRole.role) && toUserIds.includes(loanRole.user.id));
    return (invisibleLendersSelected || isSenderInvisibleToBorrower) && borrowersSelected;
}


const dedupedElements = (elements: FormElementV2ResponseDtoExtended[]) => {
    const dedupedElements = elements.reduce((acc, element) => {
        if (!acc.some(accElement => accElement.id === element.id)) {
            return [...acc, element];
        }
        return acc;
    }, [] as FormElementV2ResponseDtoExtended[]);
    return dedupedElements;
}
export const getMessageBodyWithBorrowerPortalLinks = async (messageBody: string, loanId: string, lenderId: string, receiverId: string, dispatch: AppDispatch): Promise<string> => {
    if (!messageBody) return '';
    // get all links from the message body
    const links = getLinksFromHtml(messageBody);
    //if the recipient role is a borrower, we will replace the links
    const newLinks = await Promise.all(links.map(async (link) => {
        // if link container query param formElementId
        // we need to parse formElementId from link
        // and call the api to get the link for the borrower portal
        // and replace the link in the message body
        // with the new link
        const formElementId = link.match(/formElementId=([^&]*)/);
        const firstMatch = formElementId && formElementId[1];
        if (firstMatch) {
            try {
                const promise = await dispatch(loanApi.endpoints.createDeepLinkActionForObject.initiate({
                    infoId: firstMatch,
                    companyID: lenderId,
                    loanId: loanId,
                    forUserId: receiverId,
                    actionType: null,
                    messageId: null,
                    taskViewType: null
                })).unwrap();
                return promise.url;
            } catch {
                return link;
            }
        }

        return link
    })).then((responses) => responses.map(response => response));

    // replace the links in the message body
    // with the new links
    const updatedBodyWithLinks = links.reduce((body, link, index) => {
        return body.replace(link, newLinks[index]);
    }, messageBody);

    return updatedBodyWithLinks;
}

const getLinksFromHtml = (html: string): string[] => {
    // get all a tag links from the message body
    // that contain the word "formElementId"
    // as a list of links
    // without using DomParser
    const links = html.match(/href="([^"]*)"/g);

    if (!links) return [];

    return links.map(link => link.replace('href="', '').replace('"', ''))
}